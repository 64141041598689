import {Outlet} from "react-router-dom";
import {Helmet} from "react-helmet";
import favicon from "./kruxet.png"
import * as React from 'react';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";

const theme = createTheme();

export default function Root() {
  return <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{"Kruxet"}</title>
          <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <div id="detail">
          <ThemeProvider theme={theme}>
              <AppBar
                  position="fixed"
              >
                  <Toolbar>
                      <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                          Kruxet
                      </Typography>
                      <Button color="inherit" onClick={()=>{window.open("https://app.kruxet.com/signin", "_self")}}>Sign in to the Console</Button>

                  </Toolbar>
              </AppBar>
              <Outlet />
          </ThemeProvider>
      </div>
  </>
}
