import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    wheelStep: 0.1,
    initialScale: 2,
}

const editorSlice = createSlice({
    name: "editor",
    initialState,
    reducers: {
        setProperty: {
            reducer(state, action) {
                if (action.payload.property !== undefined && action.payload.value !== undefined)
                    state[action.payload.property] = action.payload.value
            }
        }
    }
})

export const editorReducer = editorSlice.reducer
export const setProperty = editorSlice.actions.setProperty
