import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {CodeBlock} from "react-code-blocks";

export default function Embed() {
    return <Box sx={{
        display: 'flex',
        backgroundColor: "rgba(255, 242, 99, 0.1)",
        marginTop: 8,
        paddingBottom: 10
    }}>
        <CssBaseline/>
        <Container maxWidth="md" sx={{
            backgroundImage: "linear-gradient(to right, rgba(255, 242, 99, 0.3), rgba(255, 242, 99, 0.7))",
            paddingBottom: 5
        }}>
            <Box textAlign='left'>
                <Typography variant="h3" component="div">
                    <p>Embed crosswords</p>
                </Typography>
                <Typography variant="body2" component="div">
                    <p>
                        To embed a crossword on a site, you need load a prebuilt react App.
                        The app will load the crossword data and display it on the web page.
                    </p>
                    <p>
                        You can typically add this code to your html to try it out.
                    </p>

                    <CodeBlock
                        text={`<script>window.kruxet_config = {streamId: "TobiasSingleEntries",singleEntry: "1616735579727"}</script>
<div id="root"></div>
<link href="https://d31agymsqnwyv.cloudfront.net/solver/v2/static/css/main.dfdce99b.css" rel="stylesheet">
<link href="https://d31agymsqnwyv.cloudfront.net/solver/v2/kruxet.css" rel="stylesheet">
<script src="https://d31agymsqnwyv.cloudfront.net/solver/v2/static/js/main.e7e66ef7.js"></script>
<script src="https://d31agymsqnwyv.cloudfront.net/solver/v2/static/js/787.dbe216c4.chunk.js"></script>`}
                        showLineNumbers={false}
                        language="html"
                    >
                    </CodeBlock>
                </Typography>
                <Typography variant="h6" component="div">
                    Explained
                </Typography>
                <Typography variant="body2" component="div">
                    The first line creates a configuration object pointing out a stream and the crossword to load from the stream.
                    <CodeBlock
                        text={`<script>window.kruxet_config = {streamId: "TobiasSingleEntries",singleEntry: "1616735579727"}</script>`}
                        showLineNumbers={false}
                        language="html"
                    >
                    </CodeBlock>
                    <b/>
                    Next follows the tag where the App will be mounted.
                    <CodeBlock
                        text={`<div id="root"></div>`}
                        showLineNumbers={false}
                        language="html"
                    >
                    </CodeBlock>
                    <b/>
                    Then follows a few lines loads the js and css source of the app.
                    <CodeBlock
                        text={`<link href="https://d31agymsqnwyv.cloudfront.net/solver/v2/static/css/main.dfdce99b.css" rel="stylesheet">
<link href="https://d31agymsqnwyv.cloudfront.net/solver/v2/kruxet.css" rel="stylesheet">
<script src="https://d31agymsqnwyv.cloudfront.net/solver/v2/static/js/main.e7e66ef7.js"></script>
<script src="https://d31agymsqnwyv.cloudfront.net/solver/v2/static/js/787.dbe216c4.chunk.js"></script>`}
                        showLineNumbers={false}
                        language="html"
                    >
                    </CodeBlock>

                </Typography>
                <Typography variant="h4" component="div">
                    Wordpress
                </Typography>
                <Typography variant="body2" component="div">
                    You can add a crossword to a post by just pasting the above html code.
                    <br/>
                    There is a known caveat though, that the Gutenberg block editor does not work well with the app.
                    So you might have to deactivate it to avoid issues when activating deactivating fullscreen mode
                    of the crossword app.
                </Typography>

                <Typography variant="h4" component="div">
                    React
                </Typography>

                <Typography variant="body2" component="div">
                    Soon there will be an open source package available to include the crossword
                    in your own React App as a React Component.
                </Typography>
            </Box>
        </Container>
    </Box>
}
