import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getLists, getGetLists} from "../../backend";

export const fetchLists = createAsyncThunk("lists/fetchStatus", async () => {return getLists()})
export const fetchWords = createAsyncThunk("lists/fetchWords", async (payload, {rejectWithValue, getState}) => {
    const state = getState()

    payload.lists = payload.lists.filter(entry => {
        const lastFetch = state?.persistedReducer?.lists?.wordLists?.[entry]?.fetched
        const indexEntry = state?.persistedReducer?.lists?.lists?.find(e2=>e2.name === entry)
        const indexFetch = indexEntry?.lastModified
        return !lastFetch || !indexFetch || (new Date(lastFetch) < new Date(indexFetch)) || (new Date().getTime() - new Date(lastFetch).getTime() > 1000*(3600-60))
    })
    if (payload.lists.length === 0) {
        return rejectWithValue({message: "No need to fetch"})
    }
    const result = await getGetLists(payload)
    const now = new Date().toISOString()
    console.log("Returning wordlists")
    return result.map(i => ({...i, fetched: now}))
})

const initialState = {
    loading: false,
    lists: [],
    wordLists: {}
}

export const wordLists = createSlice({
    name: "wordLists",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchLists.pending]: (state) => {
            state.loading = false
        },
        [fetchLists.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.lists = payload
        },
        [fetchLists.rejected]: (state, {error}) => {
            console.error(error)
            state.loading = false
        },
        [fetchWords.pending]: (state) => {
            state.loading = false
        },
        [fetchWords.fulfilled]: (state, { payload }) => {
            state.loading = false
            payload.forEach(entry=> {
                state.wordLists[entry.list] = {url: entry.url, fetched: entry.fetched}
            })
        },
        [fetchWords.rejected]: (state, value) => {
            state.loading = false
        }
    }
})

export const wordListsReducer = wordLists.reducer
