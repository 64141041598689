const URL = "https://jh0gpkrasj.execute-api.eu-north-1.amazonaws.com/dev/streams"

export const openGetGetImg = async (streamName, puzzleId, imageName) => {
  const response = await fetch(`${URL}/${streamName}/puzzles/${puzzleId}/images/${imageName}`)
  console.log(response)
  const theJson = await response.json()
  console.log(theJson)
  return theJson.result.body.url
}

export const openGetGetFont = async (streamName, puzzleId, fontName) => {
  const response = await fetch(`${URL}/${streamName}/puzzles/${puzzleId}/fonts/${fontName}`)
  const theJson = await response.json()
  return theJson.result
}

export const getScores = async (streamName, puzzleId, attempt) => {
  const response = await fetch(`${URL}/${streamName}/puzzles/${puzzleId}/scores`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({attempt: attempt})
  })
  const theJson = await response.json()
  console.log(theJson)
  return theJson.result
}

export const getX = async (streamId, puzzleId) => {
  const response = await fetch (`https://jh0gpkrasj.execute-api.eu-north-1.amazonaws.com/dev/streams/${streamId}/puzzles/${puzzleId}`)
  const theJson = await response.json()
  const xObj = theJson.result.puzzle
  return xObj
}
