import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit"
import {getGetFont, getGetImg} from "../../backend"
import {openGetGetFont, openGetGetImg} from "../../openBackend"

const isWithinRange = (aTime) => {
  if (!aTime)
    return false
  // Caching for 5 days
  //return (new Date().getTime() - aTime)/1000 < 60*60*24*5
  // Caching for 5 hours
  return (new Date().getTime() - aTime)/1000 < 60*60*5
  // Cacning for 5 seconds
  //return (new Date().getTime() - aTime)/1000 < 5
}

export const fetchOpenUrl = createAsyncThunk(
    'url/fetchStatus',
    async (arg, { getState }) => {
      const state = getState()
      const cache = state?.persistedReducer?.urls?.cache
      const {streamName, puzzleId, imageName, font} = arg
      if (imageName) {
        if (isWithinRange(cache?.[imageName]?.time)) {
          return cache[imageName]
        } else {
          const url = await openGetGetImg(streamName, puzzleId, imageName)
          return {imageName: imageName, url, time: new Date().getTime(), created: new Date().toLocaleString()}
        }
      } else if (font) {
        if (isWithinRange(cache?.[font]?.time) && cache?.[font]?.url) {
          return cache[font]
        } else {
          const openResult = await openGetGetFont(streamName, puzzleId, font)
          const url = openResult
          return {fontName: font, url, time: new Date().getTime(), created: new Date().toLocaleTimeString()}
        }
      }
    }
)

export const fetchUrl = createAsyncThunk(
    'url/fetchStatus',
    async (arg, { getState }) => {
      const state = getState()
      const cache = state?.persistedReducer?.urls?.cache
      //console.log(cache)
      const {xId, userId, image, font} = arg
      if (image) {
        if (isWithinRange(cache?.[image]?.time)) {
          return cache[image]
        } else {
          const url = await getGetImg(xId, userId, image)
          return {imageName: image, url, time: new Date().getTime(), created: new Date().toLocaleString()}
        }
      } else if (font) {
        const fontName = font.name
        //console.log(font)
        if (isWithinRange(cache?.[fontName]?.time) && cache?.[fontName]?.url) {
          return cache[fontName]
        } else {
          try {
            const url = await getGetFont(userId, fontName)
            return {fontName: fontName, url, time: new Date().getTime(), created: new Date().toLocaleTimeString()}
          } catch(err) {
            console.error(err)
            throw err
          }
        }
      }
    }
)

const initialState = {
  loading: 'idle',
  cache: {}
}

export const urlSlice = createSlice({
  name: 'urlCache',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: {
    [fetchUrl.pending]: (state) => {
      //state is actually a Proxy object. Use current from immer to get the actual object
      const readState = current(state)
      state.loading = true
      const entries = Object.keys(readState.cache).map(key => readState.cache[key])
      entries.forEach(entry => {
        const keep = isWithinRange(entry?.time)
        if (!keep) {
          console.log(`clearing entry ${entry}`)
          delete state.cache[entry.imageName||entry.fontName]
        }
      })
    },
    [fetchUrl.fulfilled]: (state, { payload }) => {
      state.cache[payload.imageName||payload.fontName] = payload
      state.loading = false
      if (payload.fontName) {
        const {fontName, url} = payload
        const family = fontName?.indexOf(".") === -1 ? fontName : fontName.split(".")[0]
        const fontFace = new FontFace(family, `url(${url})`);
        document.fonts.add(fontFace);
        fontFace.load();
      }
    },
    [fetchUrl.rejected]: (state, { error }) => {
      console.log(error)
      state.loading = false
    },
    [fetchOpenUrl.pending]: (state) => {
      //state is actually a Proxy object. Use current from immer to get the actual object
      const readState = current(state)
      state.loading = true
      const entries = Object.keys(readState.cache).map(key => readState.cache[key])
      entries.forEach(entry => {
        const keep = isWithinRange(entry?.time)
        if (!keep) {
          delete state.cache[entry.imageName||entry.fontName]
        }
      })
    },
    [fetchOpenUrl.fulfilled]: (state, { payload }) => {
      state.cache[payload.imageName||payload.fontName] = payload
      state.loading = false
      if (payload.fontName) {
        const {fontName, url} = payload
        const family = fontName?.indexOf(".") === -1 ? fontName : fontName.split(".")[0]
        const fontFace = new FontFace(family, `url(${url})`);
        document.fonts.add(fontFace);
        fontFace.load();
      }
    },
    [fetchOpenUrl.rejected]: (state, { error }) => {
      console.log(error)
      state.loading = false
    }
  }
})

export const urlReducer = urlSlice.reducer
export const clearCache = urlSlice.actions.reset
