import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Root from "./routes/route";
import ErrorPage from "./error-page";
import { Provider } from 'react-redux'
import store from "./store";
import {PersistGate} from "redux-persist/integration/react";
import {
    persistStore
} from 'redux-persist'
import StreamSolverMenu from "./routes/StreamSolverMenu";
import Landing from "./routes/landing";
import Embed from "./routes/Embed";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Landing />
            },
            {
                path: "embedding",
                element: <Embed/>
            }
        ]
    },
]);

const persistor = persistStore(store)
console.log(process.env.REACT_APP_VARIANT)

const root = ReactDOM.createRoot(document.getElementById('root'));

let appVariant = undefined
if (process.env.REACT_APP_VARIANT === "solver") {
    appVariant = <StreamSolverMenu config={window.kruxet_config}/>
} else {
    appVariant = <RouterProvider router={router} />
}
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {appVariant}
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
