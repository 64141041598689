import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  username: null
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLoggedIn: {
      reducer(state, action) {
        state.username = action.payload.username
      }
    },
    userLoggedOut: {
      reducer(state) {
        state.username = null
      }
    }
  }
})

export const { userLoggedIn, userLoggedOut } = userSlice.actions
export default userSlice.reducer