import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {listX, listXOrders} from "../../backend";

export const fetchXlist = createAsyncThunk(
    'xlist/fetchStatus',
    async () => {
      return await listX()
    })

export const fetchXOrders = createAsyncThunk(
    'xOrders/fetchStatus', async ()=>{
      return await listXOrders()
    })

const initialState = {
  entities: [],
  loading: 'idle',
  xOrders: [],
  xOrdersLoading: 'idle'
}

export const xlistSlice = createSlice({
  name: 'xlist',
  initialState,
  reducers: {
    reset: () => initialState,
    xListTimestampInvalidate: (state) => {
      delete state.xListTimestamp
    }
  },
  extraReducers: {
    [fetchXlist.pending]: (state) => {
      state.loading = true
    },
    [fetchXlist.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.entities = payload.Items
      state.xListTimestamp = new Date().toISOString()
    },
    [fetchXlist.rejected]: (state, { error }) => {
      console.log(error)
      state.loading = false
    },
    [fetchXOrders.pending]: (state) => {
      state.loading = true
    },
    [fetchXOrders.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.xOrders = payload.Items
      state.xOrdersTimestamp = new Date().toISOString()
    },
    [fetchXOrders.rejected]: (state, { error }) => {
      console.log(error)
      state.loading = false
    },
  }
})

export const xlistReducer = xlistSlice.reducer

export const xListTimestampInvalidate = xlistSlice.actions.xListTimestampInvalidate
