import Container from "@mui/material/Container";
import {Button, CardActionArea, CardMedia} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import Grid from '@mui/material/Grid';

export default function Landing() {

    return <Box sx={{
        display: 'flex',
        backgroundColor: "rgba(255, 242, 99, 0.1)",
        marginTop: 8,
        paddingBottom: 10
    }}>
        <CssBaseline/>
        <Container maxWidth="md" sx={{
            backgroundImage: "linear-gradient(to right, rgba(255, 242, 99, 0.3), rgba(255, 242, 99, 0.7))",
            paddingBottom: 5
        }}>
            <Box textAlign='center'>
                <Typography gutterBottom variant="h3" component="div">
                    <p>Create crosswords with Kruxet</p>
                </Typography>
                <Typography gutterBottom variant="h4" component="div">
                    <p>Embed and publish on any site</p>
                </Typography>
            </Box>
            <Box textAlign='center' sx={{display: "flex", flexDirection: "column", gap: 5}}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{
                            height: "100%"
                        }}>
                            <CardActionArea onClick={()=>{window.open("https://app.kruxet.com/signin", "_self")}}>
                                <CardMedia
                                    sx={{height: 140, backgroundPosition: "left", backgroundSize: "200%"}}
                                    image="res/images/xpart.jpeg"
                                    title="xpart">
                                    <div style={{
                                        width: "100%", height: "100%",
                                        backgroundImage: "linear-gradient(to left, rgba(205,205,205,0), rgba(205, 205, 205, 1))"}}/>
                                </CardMedia>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Create crosswords
                                    </Typography>
                                    <Typography>
                                        Kruxet is Everything you need to create and share crosswords.
                                    </Typography>
                                    <Button>Sign in to the App Console</Button>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card sx={{
                            backgroundColor: "white",
                            height: "100%"
                        }}><CardActionArea onClick={() => {window.open("https://kryssa.kruxet.com", "_self")}}>
                            <CardMedia
                                sx={{height: 140, backgroundPosition: "right", backgroundSize: "200%"}}
                                image="res/images/xpart2.jpeg"
                                title="xpart3">
                                <div style={{
                                    width: "100%", height: "100%",
                                    backgroundImage: "linear-gradient(to left, rgba(205,205,205,0), rgba(205, 205, 205, 1))"}}/>
                            </CardMedia>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Solve crosswords
                                </Typography>
                                <Typography>
                                    Everyone can access Crosswords published in streams by You and Others.
                                </Typography>
                                <Button>Try out some crosswords</Button>
                            </CardContent>
                        </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>

                <Card sx={{
                    display: 'flex'
                }}>
                    <CardActionArea onClick={()=>{window.open("https://app.kruxet.com", "_self")}}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Share with streams
                                    </Typography>
                                    <Typography>
                                        When logged in to Kruxet you can easily create a stream.
                                        You can schedule crosswords that you have created in the stream,
                                        and make it available to your own site.
                                    </Typography>
                                    <Button>Learn to share</Button>
                                </CardContent>
                            </Grid>
                            <Grid item xs={4} className={"tobias"}>
                                <CardMedia
                                    image="res/images/xpart3.jpeg"
                                    alt="Live from space album cover"
                                    sx={{height: "100%", backgroundPosition: "middle"}}
                                >
                                    <div style={{
                                        width: "100%", height: "100%",
                                        backgroundImage: "linear-gradient(to left, rgba(205,205,205,0), rgba(205, 205, 205, 1))"}}/>
                                </CardMedia>
                            </Grid>
                        </Grid>
                    </CardActionArea>
                </Card>
                <Card sx={{
                    display: 'flex'
                }}>
                    <CardActionArea onClick={()=>{window.open("/embedding", "_self")}}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} className={"tobias"}>
                                <CardMedia
                                    image="res/images/xpart2.jpeg"
                                    alt="Live from space album cover"
                                    sx={{height: "100%", backgroundSize: "400%"}}
                                >
                                    <div style={{
                                        width: "100%", height: "100%",
                                        backgroundImage: "linear-gradient(to left, rgba(205,205,205,0), rgba(205, 205, 205, 1))"}}/>
                                </CardMedia>
                            </Grid>
                            <Grid item xs={8}>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Embed on your site
                                    </Typography>
                                    <Typography>
                                        Download the Kruxet client app, deploy to your web server and configure what stream to consume.
                                    </Typography>
                                    <Button>Learn to embed</Button>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </CardActionArea>
                </Card>
            </Box>
        </Container>
    </Box>
}
